import { combineReducers } from "redux";
import { TipReducer, TipState } from "./TipRedux/TipReducer";

export type InitialRootState = {
  TipState: TipState;
};

const reducers = combineReducers({
  TipState: TipReducer,
});

export default reducers;
