import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "../../parts/Footer/Footer";
import lazyWithRetry from "../../components/lazyWithRetry/LazyWithRetry";
import HomeLoadingComponent from "../../components/HomeLoadingComponent/HomeLoadingComponent";

const LandingPage = lazyWithRetry(
  () => import("./pages/LandingPage/LandingPage")
);
const CitasPage = lazyWithRetry(() => import("./pages/Citas/CitasPage"));
const LandingTip = lazyWithRetry(
  () => import("./pages/Tips/LandingTip/LandingTip")
);
const TipNotFound = lazyWithRetry(
  () => import("./pages/Tips/TipNotFound/TipNotFound")
);
const TipPage = lazyWithRetry(() => import("./pages/Tips/TipPage/TipPage"));

const HomeRoutes = () => {
  return (
    <div className="flex flex-col">
      <div>
        <Routes>
          <Route
            path=""
            element={
              <Suspense
                fallback={<HomeLoadingComponent containerClass="py-80" />}
              >
                <LandingPage />
              </Suspense>
            }
          />
          <Route
            path="citas"
            element={
              <Suspense
                fallback={<HomeLoadingComponent containerClass="py-80" />}
              >
                <CitasPage />
              </Suspense>
            }
          />

          <Route path="tip">
            <Route
              path=""
              element={
                <Suspense
                  fallback={<HomeLoadingComponent containerClass="py-80" />}
                >
                  <LandingTip />
                </Suspense>
              }
            />
            <Route
              path=":not-found"
              element={
                <Suspense
                  fallback={<HomeLoadingComponent containerClass="py-80" />}
                >
                  <TipNotFound />
                </Suspense>
              }
            />

            <Route
              path=":title"
              element={
                <Suspense
                  fallback={<HomeLoadingComponent containerClass="py-80" />}
                >
                  <TipPage />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </div>
      <Footer />
      {/* <ModalComponent showModal={true} onClose={() => {}}></ModalComponent> */}
    </div>
  );
};

export default HomeRoutes;
