import { createContext } from "react";
// import {
//   User,
//   AccountLogin,
//   AccountRegister2,
//   RegisterContextAuthentication,
// } from "../../app/core/models/user";

interface ContextProps {
  // isLoggedIn: boolean;
  // user?: User;
  // register: (registerForm: AccountRegister2) => void;
  // login: (loginForm: AccountLogin) => void;
  // googleAuth: (
  //   authResponse: any,
  //   rememberMe: boolean,
  //   registerForm?: RegisterContextAuthentication
  // ) => void;
  // facebookAuth: (
  //   rememberMe: boolean,
  //   registerForm?: RegisterContextAuthentication
  // ) => void;
  // appleAuth: () => void;
  // logout: () => void;
}

export const AuthContext = createContext({} as ContextProps);
