import { Action } from "redux";
import { ofType } from "redux-observable";
import { of, Observable, from } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import TipService from "../../services/TipService";
import { RootState } from "../../core/models/redux";
import {
  GET_TIP,
  GET_TIP_LIST_V2,
  SET_TIP_LIST_V2,
  SET_TIP_PAGINATION,
  GET_TIP_ERROR_V2,
  GET_TIP_BY_SEARCH,
  GET_TIP_BY_SEARCH_ERROR,
  GET_TIP_CATEGORY_LIST,
  GET_TIP_CATEGORY_LIST_ERROR,
  GET_TIP_ERROR,
  SET_TIP,
  SET_TIP_CATEGORY_LIST,
  REMOVE_TIP_LIST,
} from "../types";
import { history } from "../../..";

export const getTipListEpic = (action$: Observable<Action>, state$: any) =>
  action$.pipe(
    ofType(GET_TIP_LIST_V2),
    mergeMap((action: any) => {
      const { queryString, category, currentPage, itemsPerPage } =
        action.payload;
      return from(
        TipService.getMyTips(queryString, category, currentPage, itemsPerPage)
      ).pipe(
        mergeMap((response: any) => {
          let { TipState }: RootState = state$.value;

          const pagination = {
            ...TipState.pagination,
            currentPage: response.pagination.currentPage,
            totalItems: response.pagination.totalItems,
            totalPages: response.pagination.totalPages,
          };

          return of(
            {
              type: SET_TIP_LIST_V2,
              payload: TipState.tipList.concat(response.data),
            },
            { type: SET_TIP_PAGINATION, payload: pagination }
          );
        }),
        catchError((err) => {
          return of({ type: GET_TIP_ERROR_V2 });
        })
      );
    })
  );

export const getTipCategoryListEpic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(GET_TIP_CATEGORY_LIST),
    mergeMap(() => {
      return from(TipService.getTipCategories()).pipe(
        mergeMap((response: any) =>
          of({ type: SET_TIP_CATEGORY_LIST, payload: response })
        ),
        catchError((err) => {
          return of({
            type: GET_TIP_CATEGORY_LIST_ERROR,
          });
        })
      );
    })
  );

export const getTipBySearchEpic = (action$: Observable<Action>, state$: any) =>
  action$.pipe(
    ofType(GET_TIP_BY_SEARCH),
    mergeMap((action: any) => {
      const { queryString, category } = action.payload;
      return from(TipService.getTipBySearch(queryString, category)).pipe(
        mergeMap((response: any) => {
          let { TipState }: RootState = state$.value;

          if (response.data.length === 0) {
            //reroute
            history.push("/home/tip/not-found");
          }

          const pagination = {
            ...TipState.pagination,
            currentPage: response.pagination.currentPage,
            totalItems: response.pagination.totalItems,
            totalPages: response.pagination.totalPages,
          };

          const dispatchTypes =
            response.data.length > 0 ? [{ type: REMOVE_TIP_LIST }] : [];

          return of(
            ...dispatchTypes,
            {
              type: SET_TIP_LIST_V2,
              payload: response.data,
            },
            { type: SET_TIP_PAGINATION, payload: pagination }
          );
        }),
        catchError((err) => {
          console.log(err);

          return of({
            type: GET_TIP_BY_SEARCH_ERROR,
          });
        })
      );
    })
  );

export const getTipEpic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(GET_TIP),
    mergeMap((action: any) => {
      const { TipTitle } = action.payload;
      return from(TipService.getTip(TipTitle)).pipe(
        mergeMap((response: any) => {
          return of({ type: SET_TIP, payload: response });
        }),
        catchError(() =>
          of({
            type: GET_TIP_ERROR,
          })
        )
      );
    })
  );
