import React, { FC } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import LoadingComponent from "../../app/components/LoadingComponent/LoadingComponent";
// import { RootState } from "../../app/core/models/redux";
// import {
//   AccountLogin,
//   ExternalLogin,
//   NotificationParams,
//   RegisterContextAuthentication,
// } from "../../app/core/models/user";
// import useAuth from "../../app/hooks/useAuth";
// import {
//   externalLoginAction,
//   loginAction,
//   refreshTokenAction,
//   registerAction,
// } from "../../app/redux/AccountRedux/AccountActions";
// import {
//   getProtectedParametersAction,
//   getUserNotifications,
// } from "../../app/redux/CommonRedux/CommonAction";
// import { getUserProfilesAction } from "../../app/redux/CustomerRedux/CustomerAction";
// import gtag from "../../app/utils/gtag";
// import pixel from "../../app/utils/pixel";
// import { appInsights } from "../../AppInsights";
import { AuthContext } from "./AuthContext";
// import { AccountRegister2 } from "../../app/core/models/user";

/**
 * If user is logged in, call the requeried user information and settings;
 * Handle login, external authentication, register and logout methods
 * @param  {React.ReactNode} children
 * @returns {React.ReactNode} children
 */
const AuthProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  // const dispatch = useDispatch();
  // const { currentUser, token } = useAuth();
  // const { utmValues, protectedParameters, notificationParams, networkError } =
  //   useSelector((state: RootState) => state.CommonState);
  // const { customerLoaded } = useSelector(
  //   (state: RootState) => state.CustomerState
  // );

  // useEffect(() => {
  //   /* Get current user and validate session if token exists */
  //   if (token) {
  //     dispatch(refreshTokenAction());
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   if (currentUser) {
  //     if (!protectedParameters) {
  //       dispatch(getProtectedParametersAction());
  //     }

  //     /* Insights settings */
  //     appInsights.setAuthenticatedUserContext(currentUser.id);

  //     /* GOOGLE ANALYTICS - init with user */
  //     gtag("config", "G-T564CSVRYK", {
  //       user_id: currentUser.id,
  //     });

  //     /* FACEBOOK PIXEL - init with user */
  //     pixel.init("512053926148532", {
  //       em: currentUser.email,
  //       external_id: currentUser.id,
  //     });

  //     dispatch(getUserProfilesAction());
  //     dispatch(getUserNotifications());
  //   } else {
  //     /* GOOGLE ANALYTICS - init */
  //     gtag("config", "G-T564CSVRYK");

  //     /* FACEBOOK PIXEL - init */
  //     pixel.init("512053926148532");
  //   }

  //   pixel.fbq("track", "PageView");

  //   return () => {};
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentUser]);

  // useEffect(() => {
  //   if (window.fcWidget && currentUser) {
  //     window.fcWidget.setExternalId(currentUser?.id);
  //     window.fcWidget.user.setProperties({
  //       firstName: currentUser.name,
  //       lastName: currentUser.lastName,
  //       email: currentUser.email,
  //       phone: currentUser.phoneNumber,
  //       source: utmValues.utmSource,
  //       campaign: utmValues.utmCampaign,
  //       medium: utmValues.utmMedium,
  //       ...(currentUser.userInformation ?? {}),
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [window.fcWidget, currentUser]);

  // const login = (loginForm: AccountLogin) => {
  //   dispatch(
  //     loginAction({
  //       ...loginForm,
  //       notificationToken: notificationParams.notificationToken,
  //       notificationProvider: notificationParams.notificationProvider,
  //     })
  //   );
  // };

  // const register = (registerForm: AccountRegister2) => {
  //   dispatch(registerAction(registerForm));
  // };

  // const googleAuth = (
  //   authResponse: any,
  //   rememberMe: boolean,
  //   registerForm?: RegisterContextAuthentication
  // ) => {
  //   if (authResponse.tokenId) {
  //     let payload: ExternalLogin & NotificationParams = {
  //       loginExternalAccessToken: authResponse.tokenId,
  //       rememberMe: rememberMe,
  //       loginExternalProvider: "Google",
  //       notificationToken: notificationParams.notificationToken,
  //       notificationProvider: notificationParams.notificationProvider,
  //       acceptsPolicies: true,
  //       acceptsMarketing: true,
  //       isRegisterAction: false,
  //       ...(registerForm ? { ...registerForm } : {}),
  //     };
  //     dispatch(externalLoginAction(payload));
  //   }
  // };

  // const facebookAuth = (
  //   rememberMe: boolean,
  //   registerForm?: RegisterContextAuthentication
  // ) => {
  //   window.FB.login(
  //     (response: any) => {
  //       const { authResponse } = response;
  //       if (authResponse && authResponse.accessToken) {
  //         let payload: ExternalLogin & NotificationParams = {
  //           loginExternalAccessToken: authResponse.accessToken,
  //           rememberMe: rememberMe,
  //           loginExternalProvider: "Facebook",
  //           notificationToken: notificationParams.notificationToken,
  //           notificationProvider: notificationParams.notificationProvider,
  //           acceptsPolicies: true,
  //           acceptsMarketing: true,
  //           isRegisterAction: false,
  //           ...(registerForm ? { ...registerForm } : {}),
  //         };
  //         dispatch(externalLoginAction(payload));
  //       }
  //     },
  //     { scope: "public_profile,email" }
  //   );
  // };

  // const contextValidator = () => {
  //   if (token) {
  //     return customerLoaded || networkError;
  //   } else {
  //     return true;
  //   }
  // };

  return (
    <AuthContext.Provider
      value={{}}
      // value={{
      //   isLoggedIn: false,
      //   register,
      //   login,
      //   googleAuth,
      //   facebookAuth,
      //   appleAuth: () => {},
      //   logout: () => {},
      // }}
    >
      {/* {contextValidator() ? <>{children}</> : <LoadingComponent />} */}
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
