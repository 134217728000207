import React from "react";
import { ReactComponent as FacebookLogo } from "../../../assets/FacebookLogo.svg";
import { ReactComponent as InstagramLogo } from "../../../assets/InstagramLogo.svg";
import { ReactComponent as WhatsappLogo } from "../../../assets/WhatsappLogo.svg";
import { ReactComponent as FiwiFullWhiteLogo } from "../../../assets/FiwiFullWhiteLogo.svg";
import { ReactComponent as FormaLeft } from "../../../assets/FormaLeft.svg";
import { ReactComponent as FormaRight } from "../../../assets/FormaRight.svg";

function Footer() {
  return (
    <div className="z-10">
      <div className=" bg-fiwi-purple-200 relative flex flex-col lg:flex-row w-full  justify-center items-center  lg:py-4 py-10">
        <FormaRight className="absolute right-0 bottom-0 z-0 h-20 w-20 lg:h-12 lg:w-12" />
        <FormaLeft className="absolute left-0 top-0 z-0 h-20 w-20 lg:h-12 lg:w-12 ml-0" />
        <div className="flex flex-col items-center mb-5 lg:mb-0">
          <FiwiFullWhiteLogo className="lg:mr-14 mb-5 lg:mb-0" />
          <div className="md:hidden w-7 h-1 bg-fiwi-orange-100 rounded-lg"></div>
        </div>
        <div className="lg:border-l border-white lg:pl-10 flex flex-col md:flex-row justify-center items-center md:space-x-14 text-white">
          <div className="mb-4 md:mb-0 font-bold">Síguenos en las redes: </div>
          <div className="flex space-x-4">
            <a
              target="_blank"
              href="https://www.instagram.com/fiwi.pe/"
              rel="noreferrer"
            >
              <InstagramLogo className="w-7 h-7 lg:w-5 lg:h-5" />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/Fiwipe-282138506969105"
              rel="noreferrer"
            >
              <FacebookLogo className="w-7 h-7 lg:w-5 lg:h-5" />
            </a>

            <a
              target="_blank"
              href="https://api.whatsapp.com/send?phone=51924084272&text=Hola%2C%20necesito%20ayuda%20con%20mi%20Wifi!"
              rel="noreferrer"
            >
              <WhatsappLogo className="w-7 h-7 lg:w-5 lg:h-5" />
            </a>
          </div>
        </div>
      </div>
      <div style={{ fontSize: 10 }} className="py-6">
        Copyright © 2022 FIWI - Todos los derechos reservados
      </div>
    </div>
  );
}

export default Footer;
