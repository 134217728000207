import React, { FC } from "react";
// import { useDispatch } from "react-redux";
// import { useLocation } from "react-router";
// import { getDocTypesAction, getGeneralParametersAction } from "../../app/redux/CommonRedux/CommonAction";
import { AppContext } from "./AppContext";

// const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// };

/**
 * Call the required data and settings for the application
 * @param  {React.ReactNode} children
 * @returns {React.ReactNode} children
 */
const AppProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  // let query = useQuery();
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   /* Get doctypes and generalParameters */
  //   // dispatch(getDocTypesAction());
  //   // dispatch(getGeneralParametersAction());
  //   // let newUtmValues: UtmValues = {
  //   //   utmSource: query.get("utm_source") ?? undefined,
  //   //   utmCampaign: query.get("utm_campaign") ?? undefined,
  //   //   utmMedium: query.get("utm_medium") ?? undefined,
  //   // };
  //   // dispatch(setUtmValuesAction(newUtmValues));
  //   // const originalUserID = localStorage.getItem("originalUserId");
  //   // if (originalUserID) {
  //   //   dispatch(setOriginalUserIdAction(originalUserID));
  //   // }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return <AppContext.Provider value={{}}>{children}</AppContext.Provider>;
};

export default AppProvider;
