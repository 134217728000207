import { combineEpics } from "redux-observable";
import {
  getTipBySearchEpic,
  getTipCategoryListEpic,
  getTipEpic,
  getTipListEpic,
} from "./TipRedux/TipEpic";

const rootEpic = combineEpics(
  getTipCategoryListEpic,
  getTipBySearchEpic,
  getTipEpic,
  getTipListEpic
);

export default rootEpic;
