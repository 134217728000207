import { requests } from "../core/axiosAgent";

const buildQueryParams = (baseUrl: string, queryParams: any) => {
  var queryParamsString = "";
  var index = 0;
  for (const [key, value] of Object.entries(queryParams)) {
    if (value) {
      if (index === 0) {
        queryParamsString = `${queryParamsString}?${key}=${value}`;
      } else {
        queryParamsString = `${queryParamsString}&${key}=${value}`;
      }

      index++;
    }
  }
  return baseUrl + queryParamsString;
};

const getMyTips = (
  queryString: string,
  category: string,
  pageNumber: number,
  pageSize: number
) =>
  requests.get(
    buildQueryParams("/fiwi/blog/list", {
      queryString,
      category,
      pageNumber,
      pageSize,
    })
  );

const getTip = (title: string) => requests.get(`/fiwi/blog/?title=${title}`);

const getTipCategories = () => requests.get("/fiwi/blog/categories");

const getTipBySearch = (queryString?: string, category?: string) =>
  requests.get(
    buildQueryParams("/fiwi/blog/list", {
      queryString,
      category,
      pageNumber: 1,
      pageSize: 5,
    })
  );

const tipService = {
  getMyTips,
  getTip,
  getTipCategories,
  getTipBySearch,
};

export default tipService;
