import React from "react";
import { Helmet } from "react-helmet";
import HomeNavbar from "../../parts/HomeNavbar/HomeNavbar";

interface Props {
  title: string;
  description?: string;
  imageUrl?: string;
  children: React.ReactNode;
  canonicalPath?: string;
}

export const HomeLayout = (props: Props) => {
  return (
    <div>
      <Helmet>
        <title>{props.title}</title>
        <meta name="description" content={props.description} />
        <meta name="og:description" content={props.description} />
        <meta name="og:image" content={props.imageUrl} />

        {props.canonicalPath && (
          <link
            rel="canonical"
            key="canonical"
            href={`https://fiwi.pe${props.canonicalPath}`}
          />
        )}
      </Helmet>
      <HomeNavbar />
      <div className="h-full">
        <section>{props.children}</section>
      </div>
    </div>
  );
};
