/* TIP TYPES*/
/* export const GET_TIP_LIST = "GET_TIP_LIST";
export const GET_TIP_LIST_ERROR = "GET_TIP_LIST_ERROR"; */
export const REMOVE_TIP_LIST = "REMOVE_TIP_LIST";

export const SET_TIP_SEARCHSTATE = "SET_TIP_SEARCHSTATE";

export const GET_TIP = "GET_TIP";
export const SET_TIP = "SET_TIP";
export const GET_TIP_ERROR = "GET_TIP_ERROR";

export const GET_TIP_CATEGORY_LIST = "GET_TIP_CATEGORY_LIST";
export const SET_TIP_CATEGORY_LIST = "SET_TIP_CATEGORY_LIST";
export const GET_TIP_CATEGORY_LIST_ERROR = "GET_TIP_CATEGORY_LIST_ERROR";

export const GET_TIP_BY_SEARCH = "GET_TIP_BY_SEARCH";
export const GET_TIP_BY_SEARCH_ERROR = "GET_TIP_BY_SEARCH_ERROR";

export const REMOVE_TIP = "REMOVE_TIP";

export const GET_TIP_LIST_V2 = "GET_TIP_LIST_V2";
export const SET_TIP_LIST_V2 = "SET_TIP_LIST_V2";
export const GET_TIP_ERROR_V2 = "GET_TIP_ERROR_V2";

export const SET_COMMON_TIP_LIST = "SET_COMMON_TIP_LIST";

export const SET_TIP_PAGINATION = "SET_TIP_PAGINATION";
export const REMOVE_TIP_PAGINATION = "REMOVE_TIP_PAGINATION";

export const TEST_TIP = "TEST_TIP";
