import React, { useEffect } from "react";
import "./App.css";
import { Navigate, Route, Routes } from "react-router-dom";
import HomeRoutes from "./modules/home/HomeRoutes";
import gtag from "../utils/gtag";

function App() {
  useEffect(() => {
    gtag("js", new Date());
    gtag("config", "G-ZSFGQ2119X");
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate replace to="/home" />} />
        <Route path="/home/*" element={<HomeRoutes />} />
      </Routes>
    </div>
  );
}

export default App;
