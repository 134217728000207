// import { createStore, applyMiddleware, compose } from "redux";
// import thunk from "redux-thunk";
// import reducer from "../store/reducers";

// export const store = createStore(reducer, compose(applyMiddleware(thunk)));

import { createStore, applyMiddleware, compose } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { Action } from "redux-actions";
import reducers, { InitialRootState } from "../redux/reducers";
import epics from "../redux/epics";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: Function;
  }
}

const composeEnhancers =
  (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const epicMiddleware = createEpicMiddleware<
  Action<any>,
  Action<any>,
  InitialRootState
>();

function configureStore(initialState?: InitialRootState) {
  const middleware = [epicMiddleware];
  const enhancer = composeEnhancers(applyMiddleware(...middleware));
  return createStore(reducers, initialState, enhancer);
}

const store = configureStore();

epicMiddleware.run(epics as any);

export default store;
