import { Action } from "redux-actions";
import { Tip } from "../../core/models/tip";
import { Pagination } from "../../core/models/pagination";
import {
  GET_TIP_LIST_V2,
  SET_TIP_LIST_V2,
  SET_TIP_PAGINATION,
  GET_TIP_ERROR_V2,
  SET_COMMON_TIP_LIST,
  GET_TIP,
  GET_TIP_BY_SEARCH,
  GET_TIP_BY_SEARCH_ERROR,
  GET_TIP_CATEGORY_LIST,
  GET_TIP_CATEGORY_LIST_ERROR,
  GET_TIP_ERROR,
  REMOVE_TIP,
  SET_TIP,
  SET_TIP_CATEGORY_LIST,
  REMOVE_TIP_PAGINATION,
  REMOVE_TIP_LIST,
  SET_TIP_SEARCHSTATE,
} from "../types";

export interface TipState {
  loading: boolean;
  tipList: Array<Tip>;
  categoryList: Array<string>;
  pagination: Pagination;
  tip: Tip | null;
  commonTIPList: Array<Tip>;
  searchState: {};
}

const initialState: TipState = {
  loading: false,
  tipList: [],
  categoryList: [],
  pagination: {
    currentPage: 1,
    itemsPerPage: 5,
    totalItems: 0,
    totalPages: 0,
  },
  tip: null,
  commonTIPList: [],
  searchState: {
    queryString: "",
    category: "",
  },
};

export const TipReducer = (state = initialState, action: Action<any>) => {
  switch (action.type) {
    case GET_TIP_LIST_V2:
    case GET_TIP_CATEGORY_LIST:
    case GET_TIP_BY_SEARCH:
    case GET_TIP:
      return {
        ...state,
        loading: true,
      };

    case SET_TIP:
      return {
        ...state,
        loading: false,
        tip: action.payload,
      };
    case SET_COMMON_TIP_LIST:
      return {
        ...state,
        //loading: false,
        commonTipList: action.payload,
      };
    case SET_TIP_LIST_V2:
      return {
        ...state,
        tipList: action.payload,
        loading: false,
      };
    case SET_TIP_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };
    case SET_TIP_CATEGORY_LIST:
      return {
        ...state,
        loading: false,
        categoryList: action.payload,
      };
    case SET_TIP_SEARCHSTATE:
      return {
        ...state,
        searchState: action.payload,
      };
    case GET_TIP_CATEGORY_LIST_ERROR:
    case GET_TIP_BY_SEARCH_ERROR:
    case GET_TIP_ERROR:
    case GET_TIP_ERROR_V2:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_TIP:
      return {
        ...state,
        loading: false,
        tip: initialState.tip,
      };
    case REMOVE_TIP_LIST:
      return {
        ...state,
        loading: false,
        tipList: initialState.tipList,
      };
    case REMOVE_TIP_PAGINATION:
      return {
        ...state,
        pagination: initialState.pagination,
      };
    default:
      return state;
  }
};
