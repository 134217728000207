import axios, { AxiosResponse } from "axios";
// import { PaginatedResult } from "./models/pagination";
import { history } from "../../index";

axios.defaults.baseURL = process.env.REACT_APP_API_URL || "";

axios.interceptors.request.use((config: any) => {
  config.headers["Ocp-Apim-Subscription-Key"] =
    process.env.REACT_APP_AZURE_APIM_KEY;

  /*   const token = localStorage.getItem("jwt"); // store.commonStore.token;
  if (token) config.headers.Authorization = `Bearer ${token}`; */

  return config;
});

axios.interceptors.response.use(
  async (response: any) => {
    response = response.data.data;
    let newResponse: any = {};
    if (response.items) {
      let pagination = {
        currentPage: response.pageNumber,
        totalPages: response.totalPages,
        itemsPerPage: response.pageSize,
        totalItems: response.totalCount,
      };
      newResponse = {
        data: {
          data: response.items,
          pagination,
        },
      };
    } else {
      newResponse = { data: response };
    }
    return newResponse;
  },
  (error: any) => {
    if (!error.response) {
      // network error
      /* console.error("Network Error");
      store.dispatch({ type: SET_NETWORK_ERROR, payload: true });
      store.dispatch(
        showAlertModalAction({
          type: Type.warning,
          title: "¡Uy! Algo salió mal...",
          subTitle:
            "Por favor, refresque la página o intenté mas tarde. Si el problema persiste por favor contáctanos.",
          component: NoConnectionInfo,
        })
      ); */
    } else {
      let { data, status, headers, errors } = error.response!;
      /* andleFrontEndVersion(headers); */
      switch (status) {
        case 401:
          const token = localStorage.getItem("jwt");
          if (
            token &&
            (headers["www-authenticate"]?.startsWith("Invalid Refresh Token") ||
              headers["www-authenticate"]?.startsWith("Empty Refresh Token"))
          ) {
            console.log("logout");
            // store.dispatch(logoutAction());
          }
          break;
        case 403:
          data = "Usted no tiene acceso a este recurso";
          break;
        case 404:
          if (errors) history.push("/not-found");
          break;
        case 500:
          history.push("/home/server-error");
          break;
      }
      handleErrors(data);
    }
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
  get: <T>(url: string) =>
    axios.get<T>(url, { withCredentials: true }).then(responseBody),
  getBlob: <T>(url: string) =>
    axios
      .get<T>(url, { withCredentials: true, responseType: "blob" })
      .then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(url, body, { withCredentials: true }).then(responseBody),
  put: <T>(url: string, body: {}) =>
    axios.put<T>(url, body, { withCredentials: true }).then(responseBody),
  patch: <T>(url: string, body: {}) =>
    axios.patch<T>(url, body, { withCredentials: true }).then(responseBody),
  delete: <T>(url: string) =>
    axios.delete<T>(url, { withCredentials: true }).then(responseBody),
};

export { requests };

/* const handleFrontEndVersion = (headers: any) => {
  const currentFrontEndVersion =
    store.getState().CommonState.frontEndVersion ??
    localStorage.getItem("frontEndVersion");

  const commingFrontEndVersion = headers["frontend-version"];

  if (commingFrontEndVersion) {
    if (!currentFrontEndVersion) {
      localStorage.setItem("frontEndVersion", commingFrontEndVersion);
      store.dispatch({
        type: "SET_APP_VERSION",
        payload: commingFrontEndVersion,
      });
    } else if (currentFrontEndVersion !== commingFrontEndVersion) {
      localStorage.setItem("frontEndVersion", commingFrontEndVersion);
      store.dispatch({
        type: "SET_APP_VERSION",
        payload: commingFrontEndVersion,
      });
      window.location.reload();
    }
  }
};
 */
const handleErrors = (data: any) => {
  let requestErrors: Array<string> = [];
  if (data.errors) {
    for (const key in data.errors) {
      if (data.errors[key])
        requestErrors = [...requestErrors, ...data.errors[key]];
    }
  }
  if (typeof data === "string") requestErrors.push(data);
  if (requestErrors.length > 0) throw requestErrors.flat();
};
